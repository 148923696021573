var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { accountIdState, appSessionState } from 'src/state';
import { useFetch } from './useFetch';
import { useFetchWithoutAuth } from './useFetchWithoutAuth';
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
var useAccount = function () {
    var _a;
    var _b = useFetch(), customFetch = _b.request, toastRequest = _b.toastRequest;
    var customFetchWithoutAuth = useFetchWithoutAuth().request;
    var accountId = useRecoilValue(accountIdState);
    var appSessionAccountId = ((_a = useRecoilValue(appSessionState)) !== null && _a !== void 0 ? _a : {}).accountId;
    var navigate = useNavigate();
    var useGetAccountList = function (options) {
        return useQuery(['accountList'], function () {
            return customFetch({
                method: 'GET',
                url: 'users/account_list?device=web',
            });
        }, __assign({}, options));
    };
    var useGetAccountDetail = function () {
        return useQuery(['account', accountId, appSessionAccountId], function () {
            return customFetch({
                method: 'GET',
                url: "accounts/".concat(accountId),
                onAuthorizedError: function () {
                    navigate('/unauthorized');
                },
            });
        }, {
            enabled: !!accountId && accountId === appSessionAccountId,
            retry: false,
        });
    };
    var useGetAccountStripeBillingStatus = function () {
        return useQuery(['accountStripeBillingStatus', accountId], function () {
            return customFetch({
                method: 'GET',
                url: "billing/subscription_status",
            });
        }, {
            enabled: !!accountId,
        });
    };
    var useMoveNode = function () {
        return useMutation(function (request) {
            return customFetch({
                method: 'POST',
                url: "accounts/move_node",
            }, request);
        });
    };
    var useGetAccountCollectionOptionsQuery = function (params) {
        var _a;
        return useQuery(['collectionOptions', accountId], function () {
            return customFetch({
                method: 'GET',
                url: "collections/options/".concat(accountId),
            });
        }, __assign(__assign({}, params === null || params === void 0 ? void 0 : params.options), { enabled: accountId !== '' && ((_a = params === null || params === void 0 ? void 0 : params.options) === null || _a === void 0 ? void 0 : _a.enabled) }));
    };
    var useGetAccountPlainSelectSchemasQuery = function (params) {
        var _a;
        return useQuery(['plainSelectSchemas', accountId], function () {
            return customFetch({
                method: 'GET',
                url: "collections/schemas/plain_select_schemas/".concat(accountId),
            });
        }, __assign(__assign({}, params === null || params === void 0 ? void 0 : params.options), { enabled: accountId !== '' && ((_a = params === null || params === void 0 ? void 0 : params.options) === null || _a === void 0 ? void 0 : _a.enabled) }));
    };
    var useGetAccountCollectionTemplateCategoriesQuery = function (options) {
        return useQuery(['collectionTemplateOptions'], function () {
            return customFetch({
                method: 'GET',
                url: 'collection_templates',
            });
        }, __assign({}, options));
    };
    var getCollectionOptions = function () {
        return customFetch({
            method: 'GET',
            url: "collections/options/".concat(accountId),
        });
    };
    var useGetObjSimpleList = function (type, pagination) {
        return useQuery(["".concat(type, "SimpleList"), accountId], function () {
            return customFetch({
                method: 'POST',
                url: "".concat(type, "/simple_list"),
            }, __assign(__assign({}, pagination === null || pagination === void 0 ? void 0 : pagination.body), { accountId: accountId }));
        }, pagination === null || pagination === void 0 ? void 0 : pagination.options);
    };
    var useGetUniversalSimpleList = function (request, refCollectionId) {
        return useQuery(["universalSimpleList", refCollectionId !== null && refCollectionId !== void 0 ? refCollectionId : '', JSON.stringify(request.body)], function () {
            return customFetch({
                method: 'POST',
                url: "collections/universal_list",
            }, request.body);
        }, request.options);
    };
    var useGetUniversalSimpleListV2 = function (request) {
        var schemaId = request.schemaId;
        return useQuery(["universalSimpleListV2", schemaId], function () {
            return customFetch({
                method: 'POST',
                url: "collections/v2/universal_list",
            }, {
                schemaId: schemaId,
                accountId: accountId || undefined,
            });
        }, request.options);
    };
    var useGetUniversalSimpleListWithoutAuth = function (request) {
        var schemaId = request.schemaId;
        return useQuery(["universalSimpleList", schemaId], function () {
            return customFetchWithoutAuth({
                method: 'POST',
                url: "collections/universal_list",
            }, {
                schemaId: schemaId,
                accountId: accountId || undefined,
            });
        }, request.options);
    };
    var useGetSelectionOptionList = function (request) {
        return useQuery(['getSelectionOptionList', JSON.stringify(request)], function () {
            return customFetch({
                method: 'POST',
                url: 'collections/select_options/simple_list',
            }, request.body);
        }, request.options);
    };
    var useGetSpecificUniveralListOptionsByIds = function (request) {
        return useQuery(['specificUniversalListOptions', JSON.stringify(request)], function () {
            return customFetch({
                method: 'POST',
                url: 'collections/universal_list/options',
            }, request.body);
        }, request.options);
    };
    var useGetAutoSchedulerSetting = function (params) {
        var _a;
        return useQuery(['autoSchedulerSetting', accountId], function () {
            return customFetch({
                method: 'GET',
                url: "accounts/autoscheduler/".concat(accountId),
            });
        }, __assign({ enabled: accountId !== '' && ((_a = params === null || params === void 0 ? void 0 : params.options) === null || _a === void 0 ? void 0 : _a.enabled) }, params.options));
    };
    var useUpdateAutoSchedulerSetting = function () {
        return useMutation(function (request) {
            return customFetch({
                method: 'POST',
                url: "accounts/autoscheduler",
            }, __assign(__assign({}, request), { accountId: accountId }));
        });
    };
    var useGetShiftUserList = function () {
        return useMutation(function (request) {
            return customFetch({
                method: 'POST',
                url: 'autoscheduler/user_list',
            }, __assign(__assign({}, request), { accountId: accountId }));
        });
    };
    var useGetClientList = function () {
        return useMutation(function (request) {
            return customFetch({
                method: 'POST',
                url: 'autoscheduler/client_list',
            }, __assign(__assign({}, request), { accountId: accountId }));
        });
    };
    var useInviteEmployees = function () {
        return useMutation(function (inviteAll) {
            return toastRequest({
                method: 'POST',
                url: 'users/invite_users',
            }, {
                accountId: accountId,
                inviteAll: inviteAll,
            }, '', 'Invitations Sent Successfully!', '');
        });
    };
    var useInviteIndividualEmployee = function () {
        return useMutation(function (userRid) {
            return toastRequest({
                method: 'POST',
                url: 'users/invite_individual_user',
            }, {
                userRid: userRid,
            }, '', 'Invitation Sent Successfully!', '');
        });
    };
    var useGetGlobalSettings = function () {
        return useQuery(['globalSettings', accountId], function () {
            return customFetch({
                method: 'POST',
                url: 'users/global_filter_settings',
            }, { accountId: accountId });
        });
    };
    var useGetGlobalSettingsV2 = function () {
        return useQuery(['globalSettingsV2'], function () {
            return customFetch({
                method: 'POST',
                url: 'users/global_filter_settings/v2',
            });
        });
    };
    var getCurrentUserFetch = function () {
        return customFetch({
            method: 'GET',
            url: "users/current_user/".concat(accountId),
        });
    };
    var getCurrentAccessGroup = function () {
        return customFetch({
            method: 'GET',
            url: 'permissions/current_access_group',
        });
    };
    var getAccountAccessDataFetch = function () {
        return customFetch({
            method: 'GET',
            url: "permissions/account_access_groups/".concat(accountId),
        });
    };
    var useGetCurrentUser = function () {
        return useQuery(['currentUser', accountId], function () { return getCurrentUserFetch(); });
    };
    var useGetAccountTemplates = function () {
        return useQuery(['accountTemplate'], function () {
            return customFetch({
                method: 'GET',
                url: 'account/template',
            });
        });
    };
    var useGetAccountTemplatesCategoryMetaData = function () {
        return useQuery(['accountTemplateCategoryMetaData'], function () {
            return customFetch({
                method: 'GET',
                url: 'account/template/categories',
            });
        });
    };
    var useCreateAccountAndApplyTemplate = function () {
        return useMutation(function (body) {
            return customFetch({
                method: 'POST',
                url: 'account/template/create_from_template',
            }, body);
        });
    };
    var useGetAccountDocSigningTemplatesListQuery = function (options) {
        return useQuery(['accountSigningIntegrationTemplatesList', accountId], function () {
            return customFetch({
                method: 'GET',
                url: "ip/signing/templates/".concat(accountId),
            });
        }, options);
    };
    var getFeatureEnableStatus = function (featureName) {
        return customFetch({
            method: 'GET',
            url: "feature_flag?name=".concat(featureName),
        });
    };
    var useGetCurrentUserAccountCreatorStatus = function (email, options) {
        return useQuery(['currentUserAccountCreatorStatus', email], function () {
            return customFetch({
                method: 'POST',
                url: 'users/is_account_creator',
            }, {
                email: email,
            });
        }, __assign({}, options));
    };
    var getAuthCallbackWithLogin = function (accountId, code) {
        return customFetch({
            method: 'GET',
            url: "ip/oauth/rippling/callback_with_tb_login?code=".concat(encodeURIComponent(code), "&accountId=").concat(accountId),
        });
    };
    var useGetSchemaColumnsInCollection = function (request) {
        var collectionId = request.collectionId;
        return useQuery(['AllSchemasColumnsInCollection', collectionId], function () {
            return customFetch({
                method: 'GET',
                url: "collections/schemas/all/".concat(collectionId),
            });
        }, request.options);
    };
    var useGetCannySSOToken = function (options) {
        return useQuery(['generateCannySSOToken'], function () {
            return customFetch({
                method: 'GET',
                url: 'canny/generate_token',
            });
        }, __assign({}, options));
    };
    return {
        useGetAccountList: useGetAccountList,
        useGetAccountDetail: useGetAccountDetail,
        useGetAccountStripeBillingStatus: useGetAccountStripeBillingStatus,
        useMoveNode: useMoveNode(),
        useGetUniversalSimpleList: useGetUniversalSimpleList,
        useGetUniversalSimpleListV2: useGetUniversalSimpleListV2,
        useGetUniversalSimpleListWithoutAuth: useGetUniversalSimpleListWithoutAuth,
        useGetSpecificUniveralListOptionsByIds: useGetSpecificUniveralListOptionsByIds,
        useGetAccountCollectionOptionsQuery: useGetAccountCollectionOptionsQuery,
        useGetAccountPlainSelectSchemasQuery: useGetAccountPlainSelectSchemasQuery,
        useGetAccountCollectionTemplateCategoriesQuery: useGetAccountCollectionTemplateCategoriesQuery,
        getCollectionOptions: getCollectionOptions,
        useGetObjSimpleList: useGetObjSimpleList,
        useGetSelectionOptionList: useGetSelectionOptionList,
        useGetAutoSchedulerSetting: useGetAutoSchedulerSetting,
        useUpdateAutoSchedulerSetting: useUpdateAutoSchedulerSetting,
        useGetShiftUserList: useGetShiftUserList,
        useGetClientList: useGetClientList,
        useInviteEmployees: useInviteEmployees(),
        useInviteIndividualEmployee: useInviteIndividualEmployee(),
        getCurrentUserFetch: getCurrentUserFetch,
        useGetCurrentUser: useGetCurrentUser,
        getCurrentAccessGroup: getCurrentAccessGroup,
        getAccountAccessDataFetch: getAccountAccessDataFetch,
        useGetGlobalSettings: useGetGlobalSettings,
        useGetGlobalSettingsV2: useGetGlobalSettingsV2,
        useGetAccountTemplates: useGetAccountTemplates,
        useGetAccountTemplatesCategoryMetaData: useGetAccountTemplatesCategoryMetaData,
        useCreateAccountAndApplyTemplate: useCreateAccountAndApplyTemplate(),
        useGetAccountDocSigningTemplatesListQuery: useGetAccountDocSigningTemplatesListQuery,
        getFeatureEnableStatus: getFeatureEnableStatus,
        useGetCurrentUserAccountCreatorStatus: useGetCurrentUserAccountCreatorStatus,
        getAuthCallbackWithLogin: getAuthCallbackWithLogin,
        useGetSchemaColumnsInCollection: useGetSchemaColumnsInCollection,
        useGetCannySSOToken: useGetCannySSOToken,
    };
};
export { useAccount as useAccountApi };
