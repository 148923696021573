import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Switch } from 'antd';
import React, { useState } from 'react';
var RequiredFieldSwitch = function (_a) {
    var isRequiredDefaultValue = _a.isRequiredDefaultValue, changeSchemaDefaultConfig = _a.changeSchemaDefaultConfig;
    var _b = useState(isRequiredDefaultValue), isRequired = _b[0], setIsRequired = _b[1];
    return (_jsxs("div", { className: "mb-4", children: [_jsx("div", { className: "mb-1 text-xs font-medium opacity-[0.45]", children: "Configure" }), _jsxs("div", { className: "flex justify-between items-center", children: [_jsx("div", { className: "font-medium", children: "Mark Required" }), _jsx(Switch, { className: "mt-0", checked: isRequired, onChange: function (checked) {
                            setIsRequired(checked);
                            changeSchemaDefaultConfig({
                                required: checked,
                            });
                        } })] })] }));
};
export default React.memo(RequiredFieldSwitch);
