var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from 'antd';
import { produce } from 'immer';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { upperCaseStringToStartCase } from 'src/utils/Tools/LodashTool';
import { v4 as uuidv4 } from 'uuid';
import _, { isArray, isEqual } from 'lodash';
import { usePageApi } from 'src/hooks/api';
import { useRecoilState, useRecoilValue } from 'recoil';
import { tableSelectedViewIdState, blockTimeZoneState, collectionTableFilterPropertiesState, collectionTableSchemaIdListSelector, currentUserState, } from 'src/state';
import { isShiftRequestDisplayExtraFilter, } from 'src/components/CenterPanel/Interface';
import { ValueDataType, ClauseToHideFilterValueSet, } from 'src/shared';
import { disableScroll, getMultiFilterNames, getTagTextColor } from 'src/utils/Collection';
import ZiraDropdown from 'src/ui/ZiraDropdown';
import ZiraTooltip from 'src/ui/ZiraTooltip';
import { useTemplateComponent, useTimeZone } from 'src/hooks/component';
import { NaviBarAddButton } from 'src/utils/icons/NaviBarAddButton';
import { CheckOutlined, LoadingOutlined } from '@ant-design/icons';
import FilterEntry from './Filter/FilterEntry';
import './TableFiltersRow.scss';
var TableFiltersRow = function (_a) {
    var _b, _c;
    var defaultFilters = _a.filters, applyFilter = _a.applyFilter, collectionId = _a.collectionId, blockProps = _a.blockProps, reverseCollectionProps = _a.reverseCollectionProps, extraPropertiesToFilter = _a.extraPropertiesToFilter, canApplyFilterDefault = _a.canApplyFilter, currentGroupBySchemaId = _a.currentGroupBySchemaId, isPayPeriodModal = _a.isPayPeriodModal;
    var _d = blockProps !== null && blockProps !== void 0 ? blockProps : {}, _e = _d.blockId, blockId = _e === void 0 ? '' : _e, collectionViewId = _d.collectionViewId, isSchedule = _d.isSchedule;
    var _f = reverseCollectionProps !== null && reverseCollectionProps !== void 0 ? reverseCollectionProps : {}, recordId = _f.recordId, reverseCollectionViewId = _f.reverseCollectionViewId, schemaId = _f.schemaId, standardRelation = _f.standardRelation, standardCollectionComponent = _f.standardCollectionComponent;
    var _g = useState(), lock = _g[0], setLock = _g[1];
    var getPageFilters = usePageApi().getPageFilters;
    var _h = useState({}), dropdownsVisible = _h[0], setDropdownsVisible = _h[1];
    var viewId = useRecoilValue(tableSelectedViewIdState(blockId)) || collectionViewId;
    var lockRef = useRef(lock);
    useEffect(function () {
        lockRef.current = lock;
    }, [lock]);
    var _j = useState(defaultFilters), existingFilters = _j[0], setExistingFilters = _j[1];
    var _k = useState(false), applyingFilter = _k[0], setApplyingFilter = _k[1];
    var _l = useState(false), isInitialized = _l[0], setIsInitialized = _l[1];
    var _m = useState(), filterData = _m[0], setFilterData = _m[1];
    var _o = useState(false), isSavingFilters = _o[0], setIsSavingFilters = _o[1];
    var _p = useState(false), isSaved = _p[0], setIsSaved = _p[1];
    var _q = useRecoilState(collectionTableFilterPropertiesState(collectionId)), filterProperties = _q[0], setFilterProperties = _q[1];
    var autoApplyFilter = ((_c = (_b = useRecoilValue(currentUserState)) === null || _b === void 0 ? void 0 : _b.autoApplyFilters) !== null && _c !== void 0 ? _c : true) && canApplyFilterDefault;
    var schemaIds = useRecoilValue(collectionTableSchemaIdListSelector(collectionId));
    var columns = useMemo(function () {
        var schemasToFilter = (filterData === null || filterData === void 0 ? void 0 : filterData.schemas) || {};
        var extraSchemasToFilter = {};
        extraPropertiesToFilter === null || extraPropertiesToFilter === void 0 ? void 0 : extraPropertiesToFilter.map(function (extra) {
            return (extraSchemasToFilter[extra.id] = __assign({ canEdit: true, canOnlyUpdateEmpty: false, isRequired: false, isQuickCreate: false, uniqueId: extra.id, preventVisibilityConfiguration: false }, extra));
        });
        if ((reverseCollectionProps === null || reverseCollectionProps === void 0 ? void 0 : reverseCollectionProps.schemaId) && (filterData === null || filterData === void 0 ? void 0 : filterData.schemas)) {
            // Hide prefilter schema in custom reverse collection
            return Object.fromEntries(Object.entries(schemasToFilter).filter(function (_a) {
                var _ = _a[0], filter = _a[1];
                return filter.id !== reverseCollectionProps.schemaId;
            }));
        }
        return __assign(__assign({}, extraSchemasToFilter), schemasToFilter);
    }, [extraPropertiesToFilter, filterData === null || filterData === void 0 ? void 0 : filterData.schemas, reverseCollectionProps === null || reverseCollectionProps === void 0 ? void 0 : reverseCollectionProps.schemaId]);
    var saveTemplateLocationFilter = useTemplateComponent(blockId).saveTemplateLocationFilter;
    var checkForLocationFilter = useCallback(function (filters) {
        var filterSet = false;
        filters.map(function (filter) {
            var _a;
            if (filter.clauseType === 'native_location_id' &&
                filter.clauseValue === 'option_contains') {
                saveTemplateLocationFilter(typeof filter.filterValue === 'string'
                    ? [filter.filterValue]
                    : ((_a = filter.filterValue) !== null && _a !== void 0 ? _a : []));
                filterSet = true;
            }
        });
        if (!filterSet) {
            saveTemplateLocationFilter([]);
        }
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    var initExistingFiltersFromFilterData = function (pageFilterData) {
        var _a, _b;
        var filters = (_b = (_a = pageFilterData.existingFilters) === null || _a === void 0 ? void 0 : _a.map(function (filter) {
            var _a, _b, _c, _d, _e, _f;
            var uniqueId = filter.secondaryFilter
                ? (_a = Object.values(pageFilterData.schemas).find(function (s) {
                    return s.secondaryFilter &&
                        s.id === filter.property &&
                        s.secondaryFilter.id === filter.secondaryFilter.property;
                })) === null || _a === void 0 ? void 0 : _a.uniqueId
                : (_b = Object.values(pageFilterData.schemas).find(function (s) { return s.id === filter.property; })) === null || _b === void 0 ? void 0 : _b.uniqueId;
            var f = __assign(__assign({ id: uuidv4(), displayName: typeof filter.filterValue === 'string'
                    ? filter.filterValue !== 'current_user'
                        ? ((_d = (_c = pageFilterData.propertyNames) === null || _c === void 0 ? void 0 : _c[filter.filterValue]) !== null && _d !== void 0 ? _d : filter.filterValue)
                        : 'Current User'
                    : isArray(filter.filterValue)
                        ? getMultiFilterNames(filter, pageFilterData)
                        : filter.filterValue }, filter), { filterValue: ClauseToHideFilterValueSet.has(filter.clauseValue)
                    ? undefined
                    : filter.filterValue, uniqueId: uniqueId });
            if (filter.secondaryFilter) {
                f.secondaryFilter = __assign(__assign({ displayName: typeof filter.secondaryFilter.filterValue === 'string'
                        ? filter.secondaryFilter.filterValue !== 'current_user'
                            ? ((_f = (_e = pageFilterData.propertyNames) === null || _e === void 0 ? void 0 : _e[filter.secondaryFilter.filterValue]) !== null && _f !== void 0 ? _f : filter.secondaryFilter.filterValue)
                            : 'Current User'
                        : isArray(filter.secondaryFilter.filterValue)
                            ? getMultiFilterNames(filter.secondaryFilter, pageFilterData)
                            : filter.secondaryFilter.filterValue }, filter.secondaryFilter), { filterValue: ClauseToHideFilterValueSet.has(filter.secondaryFilter.clauseValue)
                        ? undefined
                        : filter.secondaryFilter.filterValue, uniqueId: uniqueId });
            }
            return f;
        })) !== null && _b !== void 0 ? _b : [];
        var extraDisplaySettings = pageFilterData.displaySettingsExtra;
        if (extraDisplaySettings) {
            // existing filter for shift request claimer
            if (isShiftRequestDisplayExtraFilter(extraDisplaySettings)) {
                var id = uuidv4();
                filters.unshift({
                    id: id,
                    displayName: 'Request Claimer',
                    property: 'request_claimers',
                    clauseType: 'user',
                    clauseValue: extraDisplaySettings.contains
                        ? 'option_contains'
                        : 'option_not_contains',
                    filterValue: extraDisplaySettings.claimerRids,
                    uniqueId: 'request_claimers',
                });
            }
        }
        return filters;
    };
    var extraPropertyFilterToExtra = function (property, clauseValue, filterValue) {
        if (property.id === 'request_claimers') {
            return {
                contains: clauseValue === 'option_contains',
                claimerRids: filterValue,
            };
        }
    };
    var extraPropertiesIds = useMemo(function () { return (extraPropertiesToFilter === null || extraPropertiesToFilter === void 0 ? void 0 : extraPropertiesToFilter.map(function (e) { return e.id; })) || []; }, [extraPropertiesToFilter]);
    var getCurrentDisplayExtraFromExistingFilters = useCallback(function (existingFilters) {
        var _a;
        return (_a = existingFilters
            .filter(function (f) { return extraPropertiesIds.includes(f.property || ''); })) === null || _a === void 0 ? void 0 : _a.map(function (e) {
            var foundInitExtra = extraPropertiesToFilter === null || extraPropertiesToFilter === void 0 ? void 0 : extraPropertiesToFilter.find(function (p) { return p.id === e.property; });
            return foundInitExtra
                ? extraPropertyFilterToExtra(foundInitExtra, e.clauseValue, e.filterValue)
                : undefined;
        })[0];
    }, [extraPropertiesIds, extraPropertiesToFilter]);
    var getFilteredExistingFilters = useCallback(function (existingFilters) {
        return existingFilters.filter(function (f) { return !(extraPropertiesIds === null || extraPropertiesIds === void 0 ? void 0 : extraPropertiesIds.includes(f.property || '')); });
    }, [extraPropertiesIds]);
    var initFilters = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var params, pageFilterData, filters;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    params = blockProps
                        ? { collectionViewId: viewId !== null && viewId !== void 0 ? viewId : null }
                        : {
                            collectionId: collectionId,
                            reverseCollectionViewId: reverseCollectionViewId,
                            recordId: recordId,
                            schemaId: schemaId,
                            standardRelation: standardRelation,
                            standardCollectionComponent: standardCollectionComponent,
                        };
                    return [4 /*yield*/, getPageFilters(__assign({ filters: isInitialized ? getFilteredExistingFilters(defaultFilters) : undefined, displaySettingsExtra: getCurrentDisplayExtraFromExistingFilters(defaultFilters) }, params))];
                case 1:
                    pageFilterData = _a.sent();
                    setIsInitialized(true);
                    if (pageFilterData) {
                        setFilterData(pageFilterData);
                        setFilterProperties(function (prev) { return ({
                            propertyNames: __assign(__assign({}, prev.propertyNames), pageFilterData.propertyNames),
                            propertyColors: __assign(__assign({}, prev.propertyColors), pageFilterData.propertyColors),
                        }); });
                        filters = initExistingFiltersFromFilterData(pageFilterData);
                        setExistingFilters(filters);
                        if (isSchedule) {
                            checkForLocationFilter(filters);
                        }
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [
        blockProps,
        collectionId,
        defaultFilters,
        getCurrentDisplayExtraFromExistingFilters,
        getFilteredExistingFilters,
        getPageFilters,
        isInitialized,
        isSchedule,
        recordId,
        schemaId,
        standardRelation,
        viewId,
    ]);
    useEffect(function () {
        if (!isInitialized) {
            initFilters();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isInitialized]);
    useEffect(function () {
        if (applyingFilter) {
            initFilters();
            setApplyingFilter(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applyingFilter]);
    //! Update filters on change
    useEffect(function () {
        if (isInitialized && !isEqual(defaultFilters, existingFilters)) {
            setExistingFilters(defaultFilters);
            initFilters();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultFilters]);
    //! Refetch on schema updates
    useEffect(function () {
        if (existingFilters && isInitialized && schemaIds.length) {
            initFilters();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [schemaIds]);
    useEffect(function () {
        if (filterData) {
            var filters = initExistingFiltersFromFilterData(filterData);
            setExistingFilters(filters);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterData]);
    var onEditFilterEntry = useCallback(function (newEntry, index) {
        setExistingFilters(produce(function (draft) {
            draft[index] = newEntry;
        }));
    }, []);
    var onDeleteEntry = useCallback(function (filterId) {
        var newExistingFilters = existingFilters.filter(function (filter) { return filter.id !== filterId && filter.property !== filterId; });
        applyFilter(getFilteredExistingFilters(newExistingFilters), autoApplyFilter, getCurrentDisplayExtraFromExistingFilters(newExistingFilters)).then(function () {
            setExistingFilters(newExistingFilters);
            disableScroll(false);
            setApplyingFilter(true);
        });
    }, [
        applyFilter,
        autoApplyFilter,
        existingFilters,
        getCurrentDisplayExtraFromExistingFilters,
        getFilteredExistingFilters,
    ]);
    var onAddNewFilter = useCallback(function () {
        if (!Object.keys(columns).length)
            return;
        var defaultColumnKey = Object.keys(columns)[0];
        var defaultChosenColumn = columns[defaultColumnKey];
        var columnValues = Object.values(columns);
        var newFilterId = uuidv4();
        var newFilterSetting = {
            id: newFilterId,
            property: defaultColumnKey,
            clauseType: columnValues.length ? columnValues[0].type : ValueDataType.TEXT,
            clauseValue: defaultChosenColumn.modifiers[0].value,
            filterValue: '',
            uniqueId: defaultChosenColumn.uniqueId,
        };
        setExistingFilters(function (prev) { return __spreadArray(__spreadArray([], prev, true), [newFilterSetting], false); });
        onDropdownVisibleChange(true, newFilterId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columns]);
    var blockTimeZone = useRecoilValue(blockTimeZoneState(blockId));
    var timeZone = useTimeZone(blockTimeZone).timeZone;
    var renderValueTag = useCallback(function (filter) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        if (!filterData)
            return;
        var adjustedFilter = filter.secondaryFilter || filter;
        var value = typeof adjustedFilter.filterValue === 'string'
            ? ((_d = (_b = (_a = filterData.propertyNames) === null || _a === void 0 ? void 0 : _a[adjustedFilter.filterValue]) !== null && _b !== void 0 ? _b : (_c = filterProperties.propertyNames) === null || _c === void 0 ? void 0 : _c[adjustedFilter.filterValue]) !== null && _d !== void 0 ? _d : adjustedFilter.filterValue)
            : adjustedFilter.filterValue;
        var displayValue = '';
        var color = (_h = (_f = (_e = filterData.propertyColors) === null || _e === void 0 ? void 0 : _e[adjustedFilter.filterValue]) !== null && _f !== void 0 ? _f : (_g = filterProperties.propertyColors) === null || _g === void 0 ? void 0 : _g[adjustedFilter.filterValue]) !== null && _h !== void 0 ? _h : undefined;
        if (ClauseToHideFilterValueSet.has(adjustedFilter.clauseValue)) {
            var clauseValue = adjustedFilter.clauseValue;
            if (['current_user_is', 'current_user_is_not'].includes(clauseValue)) {
                clauseValue = clauseValue.split('_is')[0];
            }
            displayValue = upperCaseStringToStartCase(_.toUpper(clauseValue));
        }
        else if (adjustedFilter.clauseType === ValueDataType.NATIVE_BOOL) {
            var isYes = adjustedFilter.filterValue === 'true';
            displayValue = isYes ? 'Yes' : 'No';
            color = isYes ? '#E5F4EF' : '#FFEDEC';
        }
        else if (adjustedFilter.clauseType === ValueDataType.NATIVE_DATETIME ||
            adjustedFilter.clauseType === ValueDataType.DATETIME ||
            adjustedFilter.valueType === 'DATETIME') {
            displayValue = moment(adjustedFilter.filterValue).tz(timeZone).format('MMM D, YYYY');
        }
        else {
            displayValue = value !== null && value !== void 0 ? value : '';
        }
        if (isArray(displayValue)) {
            return (_jsxs(_Fragment, { children: [['!=', 'option_not_contains'].includes(adjustedFilter.clauseValue) && (_jsx("span", { className: "font-medium", children: "Not " })), displayValue.map(function (value) {
                        var _a, _b, _c, _d, _e, _f, _g, _h;
                        var name = (_d = (_b = (_a = filterData.propertyNames) === null || _a === void 0 ? void 0 : _a[value]) !== null && _b !== void 0 ? _b : (_c = filterProperties.propertyNames) === null || _c === void 0 ? void 0 : _c[value]) !== null && _d !== void 0 ? _d : value;
                        var color = (_h = (_f = (_e = filterData.propertyColors) === null || _e === void 0 ? void 0 : _e[value]) !== null && _f !== void 0 ? _f : (_g = filterProperties.propertyColors) === null || _g === void 0 ? void 0 : _g[value]) !== null && _h !== void 0 ? _h : undefined;
                        return (_jsx("div", { className: "FilterValueTag", style: {
                                backgroundColor: color,
                                color: getTagTextColor(color),
                            }, title: name, children: name }, value));
                    })] }));
        }
        return (_jsxs(_Fragment, { children: [['!=', 'option_not_contains'].includes(adjustedFilter.clauseValue) && (_jsx("span", { className: "font-medium", children: "Not " })), _jsx("div", { className: "FilterValueTag", style: {
                        backgroundColor: color,
                        color: getTagTextColor(color),
                    }, title: displayValue, children: displayValue })] }));
    }, [filterData, filterProperties, timeZone]);
    var onDropdownVisibleChange = useCallback(function (open, filterId) {
        setDropdownsVisible(function (prevDropdownsVisible) {
            var _a;
            return (__assign(__assign({}, prevDropdownsVisible), (_a = {}, _a[filterId] = open, _a)));
        });
        disableScroll(open);
        if (!open) {
            applyFilter(getFilteredExistingFilters(existingFilters), autoApplyFilter, getCurrentDisplayExtraFromExistingFilters(existingFilters)).then(function () {
                setApplyingFilter(true);
            });
        }
    }, [
        applyFilter,
        autoApplyFilter,
        existingFilters,
        getCurrentDisplayExtraFromExistingFilters,
        getFilteredExistingFilters,
    ]);
    var renderFilterTag = useCallback(function (filter) {
        var _a;
        var filterInfo = columns[filter.uniqueId];
        return (_jsxs("div", { className: "mr-2 flex h-min cursor-pointer flex-wrap\n                     rounded-md border border-gray-50 bg-white p-1 pl-2 hover:shadow-hover", onClick: function () {
                if (!filterInfo)
                    return;
                onDropdownVisibleChange(true, filter.id);
            }, children: [_jsxs("span", { className: "font-medium", children: [filterInfo === null || filterInfo === void 0 ? void 0 : filterInfo.name, (filterInfo === null || filterInfo === void 0 ? void 0 : filterInfo.secondaryFilter) ? " / ".concat(filterInfo.secondaryFilter.name) : '', ":\u00A0"] }), renderValueTag(filter)] }, ((_a = filter.secondaryFilter) === null || _a === void 0 ? void 0 : _a.id) || filter.id));
    }, [columns, onDropdownVisibleChange, renderValueTag]);
    var applyFiltersIcon = useMemo(function () {
        return isSavingFilters ? (_jsx(LoadingOutlined, {})) : isSaved ? (_jsx(CheckOutlined, { color: "#E5F4EF" })) : null;
    }, [isSavingFilters, isSaved]);
    return (_jsxs("div", { className: "TableFiltersRow", children: [existingFilters.map(function (filter, index) {
                if (((reverseCollectionProps === null || reverseCollectionProps === void 0 ? void 0 : reverseCollectionProps.schemaId) &&
                    filter.property === reverseCollectionProps.schemaId) ||
                    ((reverseCollectionProps === null || reverseCollectionProps === void 0 ? void 0 : reverseCollectionProps.recordId) &&
                        filter.filterValue &&
                        filter.filterValue[0] === (reverseCollectionProps === null || reverseCollectionProps === void 0 ? void 0 : reverseCollectionProps.recordId))) {
                    // Hide prefilter in custom/standard component reverse collection
                    return null;
                }
                if (!dropdownsVisible[filter.id]) {
                    return _jsx(_Fragment, { children: renderFilterTag(filter) });
                }
                return (_jsx(ZiraDropdown, { overlay: _jsx(FilterEntry, { blockId: blockId, existingFilter: filter, columns: columns, onDeleteEntry: function () { return onDeleteEntry(filter.id); }, onEditEntry: function (newEntry) { return onEditFilterEntry(newEntry, index); }, options: {
                            lockClose: function () { return setLock(100); },
                            lockOpen: function () { return setLock(null); },
                        }, currentGroupBySchemaId: currentGroupBySchemaId }), visible: dropdownsVisible[filter.id], onLocalVisibleChange: function (visible) {
                        onDropdownVisibleChange(visible, filter.id);
                    }, overlayClassName: "mr-4 TableFiltersRowDropdown", trigger: ['click'], placement: "bottomLeft", children: renderFilterTag(filter) }, filter.id || uuidv4()));
            }), _jsx(Button, { className: "AddTableFilterButton", icon: _jsx(NaviBarAddButton, {}), onClick: onAddNewFilter, children: "Add Filter" }), !autoApplyFilter && (_jsx(ZiraTooltip, { title: "Save Filters to Everyone".concat(isPayPeriodModal && ' (This will apply to all your pay documents)'), children: _jsx(Button, { className: "SaveTableFilterButton", style: { marginRight: blockProps ? '60px' : undefined }, icon: applyFiltersIcon, onClick: function () {
                        setIsSavingFilters(true);
                        applyFilter(getFilteredExistingFilters(existingFilters), true, getCurrentDisplayExtraFromExistingFilters(existingFilters)).then(function () {
                            setIsSavingFilters(false);
                            setIsSaved(true);
                            setTimeout(function () {
                                setIsSaved(false);
                            }, 3000);
                        });
                    }, children: "Save Filters" }) }))] }));
};
export default React.memo(TableFiltersRow);
