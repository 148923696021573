var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import _ from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useRecoilValue } from 'recoil';
import { CellInputMode, CollectionTableType, DeletableGeneratedSchemaSet, SortSettingClause, } from 'src/shared';
import { useSchemaComponent, useDisplaySettings, useCollectionComponent } from 'src/hooks/component';
import { tableCollectionIdSelector, tableDisplaySettingsState, ifCurrentUserHasDataBuilderPermission, collectionTableSchemaSelector, collectionTableCanEditSchemasSelector, } from 'src/state';
import { isSchemaTitle } from 'src/services';
import ColumnHeaderHOC from './ColumnHeaderHOC';
var ColumnHeaderFromBlock = function (props) {
    var blockId = props.blockId, schemaId = props.schemaId;
    var blockDisplaySettings = useRecoilValue(tableDisplaySettingsState(blockId));
    var _a = useSchemaComponent(), updateColumnName = _a.updateColumnName, deleteColumn = _a.deleteColumn, mutateColumn = _a.mutateColumn, addNewColumn = _a.addNewColumn, backfillGenerativeColumn = _a.backfillGenerativeColumn;
    var _b = useDisplaySettings(), addSortEntryToBlock = _b.addSortEntryToBlock, editSortEntryInBlock = _b.editSortEntryInBlock, showOrHideColumn = _b.showOrHideColumn;
    var addColorsToCollection = useCollectionComponent().addColorsToCollection;
    var _c = useState(false), isClicked = _c[0], setIsClicked = _c[1];
    var handleInsert = useCallback(function (isLeft) {
        addNewColumn === null || addNewColumn === void 0 ? void 0 : addNewColumn('block', blockId, {
            relativeSchemaId: schemaId,
            insertLocation: isLeft ? 'LEFT' : 'RIGHT',
            toDuplicate: false,
            proposedSchemaId: uuidv4(),
        });
    }, [addNewColumn, blockId, schemaId]);
    var findSortEntry = useCallback(function (property) {
        var result = _.find(blockDisplaySettings === null || blockDisplaySettings === void 0 ? void 0 : blockDisplaySettings.sortBy, function (entry) { return entry.property === property; });
        return result;
    }, [blockDisplaySettings === null || blockDisplaySettings === void 0 ? void 0 : blockDisplaySettings.sortBy]);
    var handleSort = useCallback(function (sort) {
        var entry = findSortEntry(schemaId);
        var sortOpposite = sort === SortSettingClause.ASC ? SortSettingClause.DESC : SortSettingClause.ASC;
        if (!entry) {
            addSortEntryToBlock === null || addSortEntryToBlock === void 0 ? void 0 : addSortEntryToBlock(blockId, {
                id: uuidv4(),
                property: schemaId,
                order: sort,
            });
        }
        else {
            if (entry.order === sortOpposite) {
                editSortEntryInBlock === null || editSortEntryInBlock === void 0 ? void 0 : editSortEntryInBlock(blockId, {
                    id: entry.id,
                    property: entry.property,
                    order: sort,
                });
            }
        }
    }, [addSortEntryToBlock, blockId, editSortEntryInBlock, findSortEntry, schemaId]);
    var handleUpdateColumnName = useCallback(function (editedColumn) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            updateColumnName({ requestFrom: 'block', blockId: blockId }, editedColumn.id, editedColumn);
            return [2 /*return*/];
        });
    }); }, [blockId, updateColumnName]);
    var handleDelete = useCallback(function () {
        deleteColumn({ requestFrom: 'block', blockId: blockId }, schemaId);
    }, [blockId, deleteColumn, schemaId]);
    var handleMutate = useCallback(function (editedColumn) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, mutateColumn === null || mutateColumn === void 0 ? void 0 : mutateColumn({ requestFrom: 'block', blockId: blockId }, editedColumn.id, editedColumn)];
        });
    }); }, [blockId, mutateColumn]);
    var handleHideColumn = useCallback(function () {
        showOrHideColumn(blockId, schemaId, true);
    }, [blockId, schemaId, showOrHideColumn]);
    var handleAddColors = useCallback(function () {
        addColorsToCollection(blockId);
    }, [addColorsToCollection, blockId]);
    var collectionId = useRecoilValue(tableCollectionIdSelector(blockId));
    var schema = useRecoilValue(collectionTableSchemaSelector({ collectionId: collectionId, schemaId: schemaId }));
    var canEditSchemas = useRecoilValue(collectionTableCanEditSchemasSelector({ type: CollectionTableType.TABLE, collectionId: collectionId }));
    var hasDataBuilderAccess = useRecoilValue(ifCurrentUserHasDataBuilderPermission);
    var handleBackfillColumn = useCallback(function (backfillExisting) {
        backfillGenerativeColumn(collectionId, schema, backfillExisting);
    }, [collectionId, schema, backfillGenerativeColumn]);
    // Check if can open configuration modal for thi native schema
    var isNonConfigurableNativeSchema = useMemo(function () {
        return ((schema === null || schema === void 0 ? void 0 : schema.inputMode) === CellInputMode.NATIVE &&
            !DeletableGeneratedSchemaSet.has(schema === null || schema === void 0 ? void 0 : schema.type) &&
            schema.preventVisibilityConfiguration === true);
    }, [schema]);
    var limitedFunctions = useMemo(function () {
        return ((schema === null || schema === void 0 ? void 0 : schema.inputMode) === CellInputMode.GENERATED &&
            !DeletableGeneratedSchemaSet.has(schema === null || schema === void 0 ? void 0 : schema.type));
    }, [schema]);
    var isRecordTitle = useMemo(function () { return (schema ? isSchemaTitle(schema) : false); }, [schema]);
    var canEditSchemasOverride = canEditSchemas && hasDataBuilderAccess;
    var basicDataBuilderProps = {
        handleSortClause: handleSort,
        handleInsert: handleInsert,
        hideColumn: handleHideColumn,
    };
    var extraProps = canEditSchemasOverride &&
        !limitedFunctions &&
        !isNonConfigurableNativeSchema &&
        !isRecordTitle
        ? __assign({ deleteColumn: handleDelete, mutateColumn: handleMutate, updateColumnName: handleUpdateColumnName, backfillColumn: handleBackfillColumn }, basicDataBuilderProps) : canEditSchemasOverride && isRecordTitle
        ? __assign({ addColorsToCollection: handleAddColors, updateColumnName: handleUpdateColumnName }, basicDataBuilderProps) : canEditSchemasOverride && isNonConfigurableNativeSchema && !isRecordTitle
        ? __assign({ updateColumnName: handleUpdateColumnName }, basicDataBuilderProps) : {};
    return (_jsx(ColumnHeaderHOC, __assign({ column: schema, collectionId: collectionId, isClicked: isClicked, setIsClicked: setIsClicked }, extraProps)));
};
export default React.memo(ColumnHeaderFromBlock);
